* {
  font-family: 'Roboto', sans-serif;

  border-style: none;
  border-width: 0;
  overflow: hidden;
  /*z-index: inherit;*/
}

/*
*:not(input):not(textarea), *:focus:not(input):not(textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
}
*/
body {
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
}

/*.rn-zIndex-1lgpqti {*/
/*z-index: inherit !important;*/
/*}*/

/*.rn-1lgpqti {*/
/*z-index: inherit !important;*/
/*}*/

.link {
  cursor: pointer;
}

textarea:focus, input:focus, button:focus, *:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button {
  background-color: transparent;
}

textarea {
  overflow: auto;
  /* resize: vertical !important; */
}

iframe {
  width: 99%;
}

.order ul {
  list-style: none;
  margin: 0 0 0 0.8875em;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.order ul::before,
.order ul::after {
  content: '';
  display: table;
}

.order ul::after {
  clear: both;
}

.order li {
  overflow: hidden;
  margin-left: -1.775em;
  display: flex;
}

.order li a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  position: relative;
  background: #0073ff;
  padding-left: 1.775em;
  margin-right: 1.775em;
  overflow: visible;
}

.order li a::after {
  content: '';
  position: absolute;
  right: -1.25em;
  top: 0.063em;
  width: 2.5em;
  height: 2.5em;
  background: #0073ff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: #fff #fff transparent transparent;
  border-width: 0.125em;
  border-style: solid;
  z-index: 10;
}

.order li:first-child a {
  padding-left: 0.4em;
}

.order li:last-child a {
  padding-right: 0.8em;
  margin: 0;
}

.order li:last-child a::after {
  display: none;
}

.order li:hover a,
.order li:hover a::after {
  background: #03A9F4;
}

.order li a:focus,
.order li a:focus::after,
.order li a:active,
.order li a:active::after {
  background: #00cd9e;
}

.order li.current a,
.order li.current a::after {
  background: #00cd9e;
}

.order li.current ~ li a,
.order li.current ~ li a::after {
  background: lightgray;
}

.order li.current ~ li:hover a,
.order li.current ~ li:hover a::after {
  background: darkgray;
}

.order li.current ~ li a:focus,
.order li.current ~ li a:focus::after,
.order li.current ~ li a:active,
.order li.current ~ li a:active::after {
  background: gray;
}

.order span {
  position: relative;
  z-index: 100;
  display: block;
  padding: 0.8em 0 0.8em 0.4em;
}

.leaflet-container {
  overflow: visible !important;
}

.leaflet-container * {
  overflow: visible !important;
}

.leaflet-pane {
  overflow: visible !important;
}

.leaflet-tooltip * {
  overflow: hidden !important;
}
.mapboxgl-popup, .maplibregl-popup {
  overflow: visible!important;
}
.maplibregl-popup-content, .mapboxgl-popup-content {
  padding: 0!important;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

}

/* Bim VIiewer */

#storeys {
  overflow: hidden;
  background-color: white;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 400px;
  height: 409px;
  /* margin-top: 20px; */
  opacity: 0.8 !important;
  border-radius: 15px;

  /* margin-bottom: 10px; */
  pointer-events: all;
  opacity: 1.0;

  display: none;
}

.bimMarker {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.dropdown {
  position: absolute;
  top: 10px;
  width: 95%;
  height: 35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  z-index: 1000;
}

.dropdown.opened {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown.closed {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown:hover {
  cursor: pointer;
}

.hamburger {
  color: white;
  font-size: 20px;
  margin-left: 20px;
}

.floor {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.dropdown-options {
  position: absolute;
  top: 45px;
  width: 95%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  /* border-radius: 5px; */
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
}

.option {
  border-top: 1px solid white;
  width: 100%;
  height: 35px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.option:hover {
  cursor: pointer;
}

.option .check {
  color: black;
  font-size: 20px;
  margin-left: 20px;
}

.option.selected .check {
  color: lightgreen;
}

.option .floor-number {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

